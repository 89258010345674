@tailwind base;
@tailwind components;
@tailwind utilities;
body {
   font-family: 'Roboto Condensed', sans-serif !important;
}
.box {
   box-shadow: 2px 2px 5px 5px black;
}
.prose {
   /* border: 1px solid green; */
   padding: 10px;
   width: 100%;
   height: 100%;
}
.prose img {
   width: 95%;
   height: auto;
   object-fit: cover;
   border-radius: 5px;
   margin-top: 25px;
   margin-bottom: 25px;
}

.prose p {
   margin-top: 5px;
   color: black;
   margin-bottom: 10px;
   font-weight: 400;
   font-size: 16px;
   /* border: 2px solid green; */
   /* text-decoration: underline !important; */
}
.prose p u {
   text-decoration: underline !important;
}
.prose ul {
   list-style: disc;
   /* font-weight: 500; */
   /* font-size: medium; */
   color: black;
   margin-top: 10px;
   /* font-size: 18px; */
   margin-left: 18px;
   margin-bottom: 15px;
}

.prose ul li {
   margin-bottom: 10px;
}
.prose ol li {
   list-style-type: decimal;
   margin-left: 18px;
   margin-bottom: 10px;
   color: black;
}

.prose p strong {
   /* border: 2px solid red; */
   font-size: larger;
   font-weight: bolder;
   color: black;
}

.prose h1 {
   /* font-size: 34px; */
   font-size: 2.125rem;
   font-weight: bold;
   color: black;
}
.prose h2 {
   /* font-size: 30px; */
   font-size: 1.875rem;
   font-weight: bold;
   color: black;
}
.prose h3 {
   /* font-size: 24px; */
   font-size: 1.5rem;
   font-weight: bold;
   color: black;
}
.prose h4 {
   /* font-size: 20px; */
   font-size: 1.25rem;
   font-weight: bold;
   color: black;
}
.prose h5 {
   /* font-size: 18px; */
   font-size: 1.125rem;
   font-weight: bold;
   color: black;
}
.prose h6 {
   /* font-size: 16px; */
   font-size: 1rem;
   font-weight: bold;
   color: black;
}

.divider {
   color: black !important;
   /* margin-top: 1rem; */
   border: 1px solid black;
   color: black !important;
}

/*
.table-responsive{
    height: 100% !important;
} */
.abc thead tr th {
   font-size: 13px;
}
.abc tbody tr td {
   font-size: 13px;
   font-weight: 500;
   /* overflow-y: auto; */
   /* border-radius: 5px; */
}
.abc tbody {
   height: 100px !important;
   overflow-y: scroll !important;
   position: relative;
}

.mybackground {
   background-image: url('assets/images/bike-creation-workshop.jpg');
}
.myhor h1 {
   display: flex;
   flex-direction: row;
}
.myhor h1:before,
h1:after {
   content: '';
   flex: 1 1;
   border-bottom: 1px solid;
   margin: auto;
}
.myhor h1:before {
   margin-right: 10px;
}
.myhor h1:after {
   margin-left: 10px;
}

.my .react-calendar {
   width: 400px;
   /* max-width: 100%; */
   background: white;
   border: 0px solid #a0a096 !important ;
   /* font-family: Arial, Helvetica, sans-serif; */
   line-height: 2.175em;
   color: black;
}

.my .react-calendar__tile--active {
   background: #223549;
   color: white;
   border-radius: 14px;
}

input {
   /* font: 1.25rem/3 sans-serif; */
   padding: 5px !important;
}
/* .facebook:hover {
   color: #1877f2 !important;
}
.youtube:hover {
   color: #ff0000 !important;
}
.linkedin:hover {
   color: #0077b5 !important;
}
.insta:hover {
   color: #cd486b !important;
} */
