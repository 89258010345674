// Here you can add other styles
body{
    font-family: 'Roboto Condensed', sans-serif!important;
}
.dashboard-table .card-header{
    font-size: 24px;
    font-weight: 600;
    background: white;
}
.dashboard-table table th{
    font-size: 14px !important;
    font-weight: 700;
    vertical-align: middle;
}
.dashboard-table table tbody tr td{
    font-size: 13px!important;
    font-weight: 500;
    vertical-align: middle;
}
.count-card{
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    padding: 12px 16px;
    align-items: center;
    margin-bottom: 30px;
    box-shadow: 0 2px 2px rgb(204 197 185 / 50%);
}
.iconPart{
    border: 3px solid #fff;
    height: 64px;
    width: 64px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.iconPart svg{
    height: 36px !important;
    width: 36px !important;
    color: white;
}
.count-card h2{
    font-size:32px;
    font-weight: 700;
    color:white;
}
.count-card h4{
    font-size:18px;
    font-weight: 500;
    color:white;
}
.pdf-btn{
    background: #26c6da;
    border: 1px solid #26c6da;
    color: white;
    border-radius: 5px;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card{
    box-shadow: 0 2px 2px 2px rgb(204 197 185 / 50%)!important;
    border: 0;
}
.rdt_TableCell{
    font-weight: 500;
  }
  .rdt_TableCol{
    background-color: #0168FF!important;
    color:white;
  }

  .act-btn svg{
    width: 14px!important;
        height: 14px!important;
  }

  // .dashboard-table .btn{
  //   border-right-width: 3px;
  //   border-right-color: #fff!important;
  //   color: white;
  //   font-size: 13px;
  //   background: #39f;
  // }
  // .dashboard-table .btn:last-child{
  //   border-right-color: #39f !important;
  //   font-size: 13px;
  //   background: #39f;
  //   border-right-width: 0px;
  // }

  .dashboard-table1 table th {
    font-size: 14px !important;
    font-weight: 700;
    background-color: #0168FF!important;
    color:white;
    padding: 14px 8px;
    vertical-align: middle;
}
.dashboard-table1 table td {
    font-size: 13px !important;
    font-weight: 500;
    vertical-align: middle;
}
.dashboard-table1 .btn-info{
    font-size: 13px;
    color: white;
    font-weight: 600;
    margin-right: 5px;
    background: #39f;
}
.dashboard-table1 .btn-secondary{
    font-size: 13px;
    color: #555;
    font-weight: 600;
    margin-right: 5px;
    // background:  #2eb85c;

}

.nav-btn .btn-info{
    font-size: 14px;
    color: white;
    font-weight: 600;
    margin-right: 5px;
}
.nav-btn .btn-success{
    font-size: 14px;
    color: white;
    font-weight: 600;
    margin-right: 5px;
    background:  #2eb85c;
}
.dashboard-table1 .btn-danger{
  font-size: 14px;
  color: white;
  font-weight: 600;
  margin-right: 5px;
  background:  #e55353!important;
}
.dashboard-table1 .btn-primary{
  font-size: 14px;
  color: white;
  font-weight: 600;
  margin-right: 5px;
  background:  #321fdb!important;
}

.dashboard-table1 th:first-child {
    border-top-left-radius: 12px;
  }
  .dashboard-table1 th:last-child {
    border-top-right-radius: 12px;
  }
 .ribbon {
    z-index: 10;
    width: 120px;
    height: 120px;
    overflow: hidden;
    position: absolute;
  }
   .ribbon:before,  .ribbon:after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #f6bb42;
  }
 .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 6px 0;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }
 .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
 .ribbon-top-right:before,  .ribbon-top-right:after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right:before {
    top: 0;
    left: 20px;
  }
 .ribbon-top-right:after {
    bottom: 20px;
    right: 0;
  }
 .ribbon-top-right span {
    left: -34px;
    top: 18px;
    transform: rotate(45deg);
  }
  .template-card{
    position: relative;
    border: 1px solid #eee;
    padding: 10px 16px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  .template-card a{
    text-decoration: none;
    color: #555;
    font-size: 15px;
  }
  .template-card a:hover{
    text-decoration: none;
    color: #39f;
  }
  .template-card h3:hover{
    text-decoration: none;
    color: #39f;
  }
  .template-card a h3{
    font-size: 22px;
    font-weight: 600;
    color: #555;
  }
  .template-card p{
    font-size: 13px;
    font-weight: 500;
    color: #858585;
    margin-bottom: 8px;
  }
  .tab-footer{
    text-align: right;
    padding: 10px;
    background: #eee;
    border: 1px solid #ddd;
  }

  .sidebar-nav svg.nav-icon {
    color: #0891b2!important;
}
.sidebar-nav .nav-link.active {
  background: rgb(8 145 178 / 29%)!important;
}
.sidebar{
  background: #222!important;
}
.sidebar-nav .nav-link{
  font-size: 14px!important;
    cursor: pointer;
}
.report-setting label{
  font-weight: 600;
}
.input-box{
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border:1px solid #39f;
  text-indent: 8px;
}
.input-form{
  width: 100%;
  height: 38px;
  border-radius: 0px;
  border:1px solid #ddd;
  text-indent: 8px;
  margin-bottom: 8px;
}
.form-group{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.form-group label{
  font-weight: 600;
  font-size: 14px;
  margin-right: 8px;
  width: 30%;
}
.form-group h4{
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
}
.status h4{
  font-weight: 700;
  font-size: 18px;
  margin-top: 8px;
}
.manage-temp p{
   font-size: 16px;
   font-weight: 500;
}

// body{
//   font-family: 'Montserrat', sans-serif!important;
// }
.footer{
 background:linear-gradient(to bottom, rgba(255,0,0,0), rgba(1,104,255,0.3));
 

}
// .banner{
//   background-image: linear-gradient(
//       rgba(0,0,0,0.7),
//       rgba(0,0,0,0.7)
//       );
//       background-size: cover;
//       background-repeat: no-repeat;
//       background-position: top center;
//       height: 580px;
// }
.banner li{
  color:white;
  list-style-type: disc;
}

/* ========== 05. Paul ========== */

.t-bq-quote-paul {
  position: relative;
  box-shadow: 2px 2px 25px #cecece;
  border-radius: 10px;
}

.t-bq-quote-paul .t-bq-quote-paul-pattern {
  flex-basis: 80px;
  background: url("https://raw.githubusercontent.com/taviskaron/t-div-blockquotes/main/img/hexabump.png") repeat;
  border-radius: 10px 0 0 10px;
}

.t-bq-quote-paul .t-bq-quote-paul-base {
  background: #ffffff;
  padding: 15px 30px 10px 30px;
  font-size: 11pt;
  line-height: 1.62em;
  border-radius: 10px;
}


.t-bq-quote-paul .t-bq-quote-paul-userpic {
  width: 70px;
  height: 70px;
  background: url(https://i.ibb.co/Bzvqgg3/avatar6.png) center center no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-top: -20px;
  margin-left: 30px;
}

.t-bq-quote-paul .t-bq-quote-paul-meta {
  padding-top: 15px;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author,
.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-source {
  color: #505050;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author {
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 10pt;
  font-weight: bold;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-author cite {
  font-style: normal;
}

.t-bq-quote-paul .t-bq-quote-paul-meta .t-bq-quote-paul-source {
  font-size: 10px;
}

@media screen and (max-width: 768px) {

  .t-bq-quote-paul .t-bq-quote-paul-pattern {
      flex-basis: 20px;
  }

  .t-bq-quote-paul .t-bq-quote-paul-base {
    padding: 30px 30px 20px 30px;
  }

  .t-bq-quote-paul .t-bq-quote-paul-userpic {
      width: 50px;
      height: 50px;
      left: 40px;
      top: 20px;
  }

  .t-bq-quote-paul .t-bq-quote-paul-qmark {
      left: 100px;
      top: 45px;
  }
}

.h-color:hover{
background-color: white !important;
border:3px solid #0891b2;
cursor: pointer;
}
.h-color:hover svg{
color: #0891b2!important;
}
.bg-img{
  background-image: url('../assets/images/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.banner{
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(25, 25, 25, 0.7)
    ), url('../assets/images/ban.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: center;
}
.banner1{
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(25, 25, 25, 0.7)
    ), url('../assets/images/cars.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: center;
}
.banner2{
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(25, 25, 25, 0.7)
    ), url('../assets/images/car1.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: center;
}
.collapse {
  visibility: visible!important;
}
.alter{
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.7),
    rgba(25, 25, 25, 0.7)
    ), url('../assets/images/img.jpg');
background-size: cover;
background-repeat: no-repeat;
background-position: left;
background-attachment: fixed;
}

 .calendar {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #eee;
  border-radius: 5px 5px 0 0;
}

.calendar-header button {
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 40px);
  gap: 5px;
  padding: 10px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.calendar-day-of-week {
  font-size: 14px;
  font-weight: bold;
  color: #999;
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #333;
  cursor: pointer;
}

.calendar-day-selected {
  background-color: #ddd;
}

.accordion {
  border: none!important;
  border-radius: 2px;
}
.main-notification{
  overflow-y: scroll;
  height: 80vh;
  min-height: 450px;
}



.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 420px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}


.modal.right.fade .modal-dialog {
  right: -420px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}
.modal.right .modal-content{
  border-radius: 0px!important;
}
.modal-body input{
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0px!important;
}
.modal-body .form-check-input{

  border: 1px solid #ccc!important;
}

.disply{
  display: none;
}
.dis-hide:hover .disply{
  display: block;
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #DAF6F2!important;
  border-radius: 5px;
  color: #19A893!important;
  cursor: pointer;
  width: 100%;
  text-align: left;
  height: 74px;
}

.react-tabs__tab--selected {
  background: #0168FF!important;
font-weight: 700;
  color: white!important;
  border-radius: 30px!important;
  padding: 8px 30px!important;

}
.react-tabs__tab-list {
  margin: 0 0 10px;
  padding: 3px 6px!important;
  background: #F7F9FD!important;
  border-radius: 30px!important;
  border: 1px solid #989DE4!important;
}
.react-tabs__tab:focus:after {
  content: '';

  height: 0px!important;

}



@media only screen and (max-width: 667px) and (min-width: 320px)  {
  .modal.right .modal-dialog {
    width: 390px;
}
.wrp{
  flex-wrap: wrap;
}
}
.rdt_TableRow div:nth-child(9) div{
   overflow: visible!important;
}
.abc .active{
  text-decoration: underline;
}
